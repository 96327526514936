import React from 'react';

import image1 from 'assets/images/garage/tab-1-image-1.png';
import image2 from 'assets/images/garage/tab-1-image-2.png';

const Tab1 = () => {
  const title = new Array(2).fill(0);
  
  return (
    <div className="tab-content" data-index="0">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <div className="image-1">
              <img src={image1} alt="Imagem - Suvinil" />
            </div>

            <div className="image-2">
              <img src={image2} alt="Imagem - Suvinil" />
            </div>
          </div>

          <div className="content">
            <h2 className="content-title">
              {title.map((number, index) => (
                <span key={index}>
                  <s data-index="0">D</s>
                  <s data-index="1">e</s>
                  <s data-index="2">s</s>
                  <s data-index="3">i</s>
                  <s data-index="4">g</s>
                  <s data-index="5">n</s>
                  <s data-index="6">s</s>
                  <s data-index="7">p</s>
                  <s data-index="8">r</s>
                  <s data-index="9">i</s>
                  <s data-index="10">n</s>
                  <s data-index="11">t</s>
                  <s data-index="12">.</s>
                </span>
              ))}
            </h2>

            <div className="content-description">
              <p>
                Após o período de imersão‭, ‬a equipe da ioasys compilou os<br/>
                resultados e se preparou para a semana de interação‭ - ‬onde‭, <br/>
                juntamente‭ ‬com a equipe Suvinil‭, ‬buscou soluções para as<br/>
                questões apontadas pela imersão‭. ‬A seguir‭, ‬apresenta-se um<br/>
                resumo da semana‭, ‬seguida do produto decorrente dessa agenda‭. ‬
              </p>
            </div>
          </div>
        </div>

        <div className="count">02</div>
      </div>
    </div>
  )
}

export default Tab1;