import React from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/garage/tab-4-image.jpg';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab4 = () => {
  useSplit('.section-garage .tab-content[data-index="3"] .image .image-title');
  useSplit('.section-garage .tab-content[data-index="3"] .content .content-title');

  return (
    <div className="tab-content" data-index="3">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <img src={image} alt="Imagem - Suvinil" />
            <div className="image-title">Dia 02 Buscar referências e criar.</div>
          </div>

          <div className="content">
            <div className="content-title">Dia 02 Buscar referências e criar.</div>

            <div className="content-description">
              <p>
                Com a discussão iniciada pelo benchmark‭ - ‬e estruturada a partir da imersão<br/>
                do dia anterior‭, ‬o segundo dia da semana voltou-se para a cocriação‭:<br/>
                distribuídos em duplas‭, ‬o grupo foi estimulado a criar soluções a partir dos<br/>
                produtos da discussão‭.‬ Assim‭, ‬a partir de um kit de prototipagem‭, ‬elaboraram<br/>
                telas do que imaginavam ser as soluções ideais para cada stakeholder.‬
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://drive.google.com/drive/u/0/folders/1JskKwCwOZ5Xvtso3OOrqYf5G9gVOZODH"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Ver conteúdo completo</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab4;