import { useEffect } from "react";

export const useSplit = (selector) => {
  useEffect(() => {
    const splitElement = document.querySelector(selector);
    const text = splitElement.textContent;
    splitElement.innerHTML = "";
    splitElement.classList.add('split-element');
    const split = text.split(" ");

    for (let x = 0; x < split.length; x += 1) {
      const line = split[x];
      const words = line.split(" ");

      for (let i = 0; i < words.length; i += 1) {
        const word = words[i];
        const letters = word.split("");

        if (word.length >= 1) {
          const wordElement = document.createElement("div");
          wordElement.className = "word";

          for (let y = 0; y < letters.length; y += 1) {
            const letter = letters[y];
            const letterElement = document.createElement("s");
            letterElement.textContent = letter;
            wordElement.append(letterElement);
          }

          splitElement.append(wordElement);
        }
      }
    }

    const allWords = splitElement.querySelectorAll(".word");
    const allLetters = splitElement.querySelectorAll("s");

    for (let i = 0; i < allWords.length; i += 1) {
      allWords[i].setAttribute("data-index", i);
    }

    for (let i = 0; i < allLetters.length; i += 1) {
      allLetters[i].setAttribute("data-index", i);
    }
  }, [selector]);
};