import React from 'react';

import image1 from 'assets/images/immersion/tab-1-image-1.png';
import image2 from 'assets/images/immersion/tab-1-image-2.png';

const Tab1 = () => {
  const title = new Array(2).fill(0);

  return (
    <div className="tab-content" data-index="0">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <div className="image-1">
              <img src={image1} alt="Imagem - Suvinil" />
            </div>

            <div className="image-2">
              <img src={image2} alt="Imagem - Suvinil" />
            </div>
          </div>

          <div className="content">
            <h2 className="content-title">
              {title.map((number, index) => (
                <span key={index}>
                  <s data-index="0">I</s>
                  <s data-index="1">m</s>
                  <s data-index="2">e</s>
                  <s data-index="3">r</s>
                  <s data-index="4">s</s>
                  <s data-index="5">ã</s>
                  <s data-index="6">o</s>
                  <s data-index="7">.</s>
                </span>
              ))}
            </h2>

            <div className="content-description">
              <p>
                Através de entrevistas‭, ‬foram mapeadas as dores e<br/>
                expectativas da equipe suvinil‭ - ‬foram entrevistadas pessoas do‭ <br/>
                laboratório‭, ‬setor administrativo‭, ‬SAC‭, ‬vendas‭, ‬segurança da<br/>
                informação‭, ‬TI e marketing‭. ‬Em seguida‭, ‬a equipe foi a campo‭ -<br/>
                visitando 13‭ ‬lojas distribuídas por 3‭ ‬capitais‭ (‬e região<br/>
                metropolitana‭): ‬São Paulo‭, ‬Belo Horizonte e Porto Alegre‭.‬‭ 
              </p>
            </div>
          </div>
        </div>

        <div className="count">01</div>
      </div>
    </div>
  )
}

export default Tab1;