import React, { useEffect } from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/immersion/tab-2-image.jpg';
import cursor from 'assets/vectors/cursor.svg';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab2 = () => {
  useSplit('.section-immersion .tab-content[data-index="1"] .image .image-title');
  useSplit('.section-immersion .tab-content[data-index="1"] .content .content-title');

  let index = 0;
  let transform = 0;

  const prevSlide = () => {
    const slide = document.querySelector('.section-immersion .tab-content[data-index="1"] .content-slide');
    const slideItems = slide.querySelector('.slide-items');
    const slideCursor = slide.querySelector('.cursor');
    const effect = document.createElement('div');
    effect.classList.add('effect');

    slideCursor.appendChild(effect);
    setTimeout(() => effect.classList.add('animate'), 50);
    setTimeout(() => effect.remove(), 1050);

    if (index === 0) {
      index = 2;
      transform = 1630;
      slideItems.style.transform = 'translateX(-1630px)';
    } else {
      index--;
      transform = transform - 815;
      slideItems.style.transform = 'translateX(-' + transform + 'px)';
    }
  }

  const nextSlide = () => {
    const slide = document.querySelector('.section-immersion .tab-content[data-index="1"] .content-slide');
    const slideItems = slide.querySelector('.slide-items');
    const slideCursor = slide.querySelector('.cursor');
    const effect = document.createElement('div');
    effect.classList.add('effect');

    slideCursor.appendChild(effect);
    setTimeout(() => effect.classList.add('animate'), 50);
    setTimeout(() => effect.remove(), 1050);

    if (index === 2) {
      index = 0;
      transform = 0;
      slideItems.style.transform = 'translateX(0px)';
    } else {
      index++;
      transform = transform + 815;
      slideItems.style.transform = 'translateX(-' + transform + 'px)';
    }

    console.log(transform, index);
  }

  useEffect(() => {
    const slide = document.querySelector('.section-immersion .tab-content[data-index="1"] .content-slide');
    const slideCursor = slide.querySelector('.cursor');
    const hoverLeft = slide.querySelector('.hover-left');
    const hoverRight = slide.querySelector('.hover-right');

    slide.addEventListener('mousemove', function(e) {
      let position_x = e.clientX;
      let position_y = e.clientY;

      if (slideCursor.classList.contains('hide')) slideCursor.classList.remove('hide');

      slideCursor.style.transform = 'translate('+ position_x +'px, '+ position_y + 'px';
    });

    slide.addEventListener('mouseenter', () => slideCursor.classList.remove('hide'));
    slide.addEventListener('mouseleave', () => slideCursor.classList.add('hide'));

    hoverLeft.addEventListener('mouseenter', () => slideCursor.classList.add('rotate'));
    hoverRight.addEventListener('mouseenter', () => slideCursor.classList.remove('rotate'));
  }, [])

  return (
    <div className="tab-content" data-index="1">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <img src={image} alt="Imagem - Suvinil" />
            <div className="image-title">Olhar para dentro.</div>
          </div>

          <div className="content">
            <div className="content-title">Olhar para dentro.</div>

            <div className="content-subtitle">
              <p>
                Qual a visão interna da equipe da Suvinil<br/>
                a respeito do software da Selfcolor‭?‬
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://drive.google.com/drive/u/0/folders/1JskKwCwOZ5Xvtso3OOrqYf5G9gVOZODH"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Ver conteúdo completo</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>

            <div className="content-slide">
              <div className="hover-left" onClick={prevSlide} ></div>
              <div className="hover-right" onClick={nextSlide}></div>

              <div className="cursor hide">
                <img src={cursor} alt="Ícone - Suvinil" />
              </div>

              <div className="slide-items">
                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Pouco amigável</div>

                    <div className="item-description">
                      <p>Os vendedores não‭  ‬querem vender suvinil por que tem medo de errar‭. ‬Aí eles fazem o que‭? ‬Convencem o cliente a levar o do concorrente‭.‬</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>A necessidade de operar a máquina‭ - ‬e se sujeitar a erros e prejuízos foi apontado como principal motivo pelos quais os vendedores optam por não operar o sistema‭ - ‬o que implica no declínio de vendas quando promotor não está na loja, além de demandar o SAC muitas vezes por ações que deveriam ser simples‭.‬</p>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Instável</div>

                    <div className="item-description">
                      <p>O dia que esse software parar de rodar‭, ‬nós perdemos todo nosso histórico de fórmulas‭. ‬E aí‭, ‬eu posso parar todos os lojistas‭, ‬por que ninguém consegue pigmentar uma tinta sem ele‭.</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>Números são os exemplos de como a instabilidade do sistema traz prejuízos‭ - ‬tanto para o lojista‭, ‬quanto para a suvinil‭. ‬A criação de um ambiente seguro torna-se uma das necessidades mais inerentes do projeto‭.‬ O software atual é muito pouco adaptável‭. ‬Previsto para tecnologias que já ficaram obsoletas‭, ‬resta um sistema rígido que conversa cada vez menos com as necessidades atuais‭. </p>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Potencial estratégico</div>

                    <div className="item-description">
                      <p>Para saber quem mais vendeu uma determinada cor‭, ‬precisa de no mínimo três meses‭. ‬Se‭ ‬precisar fazer uma consulta rápida ninguém consegue‭‭. O que esses dados poderiam me dizer?</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>Apesar da quantidade de funcionalidades do sistema‭, ‬ainda não há como extrair informações importantes sobre as vendas realizadas‭. ‬Isso significa um universo de informações que poderiam ser preciosas para a estratégia da Suvinil‭.‬</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab2;