import React, { useEffect } from 'react';

const Overlay = () => {
  useEffect(() => {
    const overlay = document.querySelector('.overlay');
    const width = window.innerWidth;

    if (width <= 1100) overlay.classList.add('active');

    window.addEventListener('resize', function() {
      const width = window.innerWidth;
      
      if (width <= 1100) {
        if (!overlay.classList.contains('active')) overlay.classList.add('active');
      } else {
        if (overlay.classList.contains('active')) overlay.classList.remove('active');
      }
    })
  }, [])

  return (
    <div className="overlay">
      <span>Disponível apenas<br/>para desktop.</span>
    </div>
  )
}

export default Overlay;