import React from 'react';

import image1 from 'assets/images/garage/tab-7-image-1.png';
import image2 from 'assets/images/garage/tab-7-image-2.png';
import image3 from 'assets/images/garage/tab-7-image-3.png';

const Tab7 = () => {
  return (
    <div className="tab-content" data-index="6">
      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <h2 className="content-title">Teste 01<br/>Denilson, 40 anos.</h2>

            <div className="content-description">
              <p>
                Foi promotor da Suvinil por 4 anos, trabalhou com<br/>
                a concorrência e voltou, no último ano, a trabalhar<br/>
                com selfcolor.
              </p>

              <p>
                Com a experiência - com selfcolor e concorrente, sabia<br/>
                mapear muito bem as dores comuns do dia-a-dia.<br/>
                Todas as dores que apontou já haviam sido previstas<br/>
                no teste - o que o impressionou muito positivamente. 
              </p>
            </div>
          </div>

          <div className="listing">
            <div className="item">
              <div className="item-image">
                <img src={image1} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>01</span>
                <span>"Vocês estão de brincadeira. Tudo que eu<br/>disse (que queria) vocês já fizeram!"</span>
              </div>
            </div>

            <div className="item">
              <div className="item-image">
                <img src={image2} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>02</span>
                <span>"Ficou muito, muito fácil! Qualquer<br/>vendedor pode fazer agora."</span>
              </div>
            </div>

            <div className="item">
              <div className="item-image">
                <img src={image3} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span><span>Comparação de preços</span> 03</span>
                <span>"Essa opção ficou incrível, por que posso falar<br/>com o cliente na hora (...) e mostrar na tela."</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab7;