import React, { useState, useEffect } from 'react';

import { useSplit } from "utils/general";

import background from 'assets/images/hero/background.mp4';
import overlay from 'assets/images/hero/overlay.png';
import after from 'assets/images/hero/after.png';
import keyboard1 from 'assets/images/hero/keyboard-1.png';
import keyboard2 from 'assets/images/hero/keyboard-2.png';
import logo from 'assets/vectors/logo-ioasys.svg';
import cursor from 'assets/images/hero/cursor.png';

const SectionHero = () => {
  const [videoIsActive, setActiveVideo] = useState();

  useSplit('.section-hero .wrapper .wrapper-description');

  useEffect(() => {
    const section = document.querySelector('#page-home .section-hero');
    const video = section.querySelector('.video');
    const cursor = section.querySelector('.cursor');

    setTimeout(() => section.classList.add('active'), 500);

    section.addEventListener('click', function(e) {
      if (!e.target.contains(video) && !video.classList.contains('active')) setActiveVideo(true);
    });

    video.addEventListener('click', function() {
      setActiveVideo(false);
    })

    section.addEventListener('mousemove', function(e) {
      let position_x = e.clientX;
      let position_y = e.clientY;

      if (cursor.classList.contains('hide')) cursor.classList.remove('hide');
      if (video.classList.contains('active')) cursor.classList.add('hide');

      cursor.style.transform = 'translate('+ position_x +'px, '+ position_y + 'px';
    });

    section.addEventListener('mouseenter', () => cursor.classList.remove('hide'));
    section.addEventListener('mouseleave', () => cursor.classList.add('hide'));
  }, [])

  useEffect(() => {
    const section = document.querySelector('#page-home .section-hero');
    const video = section.querySelector('.video');
    const cursor = section.querySelector('.cursor');

    if (videoIsActive) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', 'https://www.youtube.com/embed/1H4E3BDOclg');
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
      iframe.setAttribute('allowfullscreen', 'true');

      cursor.classList.add('hide')
      video.querySelector('.video-content').appendChild(iframe);
    } else if (video.querySelector('.video-content iframe')) {
      setTimeout(() => video.querySelector('.video-content iframe').remove(), 700);
    }
  }, [videoIsActive])

  return (
    <section className="section section-hero" data-index="0">
      <div className="background">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
        >
          <source src={background} />
        </video>
      </div>

      <div className={videoIsActive ? 'video active' : 'video'}>
        <div className="video-content"></div>
      </div>

      <div className="cursor hide">
        <img src={cursor} alt="Imagem - VR" />
      </div>

      <div className="hero-overlay">
        <img src={overlay} alt="Imagem - Suvinil" />
      </div>

      <div className="hero-after">
        <img src={after} alt="Imagem - Suvinil" />
      </div>

      <div className="container">
        <div className="logo">
          <img src={logo} alt="Logo - ioasys" />
        </div>

        <div className="wrapper">
          <div className="wrapper-description">Imagine it. Customize it. Color it.</div>
        </div>
      </div>

      <div className="keyboard">
        <span>Navegue usando<br/>o teclado</span>

        <div className="keyboard-image">
          <img src={keyboard1} alt="Ícone - Suvinil" />
          <img src={keyboard2} alt="Ícone - Suvinil" />
        </div>
      </div>
    </section>
  )
}

export default SectionHero;