import React from 'react';

import image1 from 'assets/images/garage/tab-10-image-1.png';
import image2 from 'assets/images/garage/tab-10-image-2.png';

const Tab10 = () => {
  return (
    <div className="tab-content" data-index="9">
      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <h2 className="content-title"> Teste 04<br/>Camilla, SAC</h2>

            <div className="content-description">
              <p>Operadora do SAC, atende dúvidas dos vendedores diariamente.</p>

              <p>
                Como interna da Suvinil, naturalmente, entende perfeitamente<br/>
                as funcionalidades e soube operá-las com tranquilidade. Não<br/>
                teve dúvidas com relação ao processo e demonstrou<br/>
                aprovação frente ao novo layout. 
              </p>
            </div>
          </div>

          <div className="listing">
            <div className="item">
              <div className="item-image">
                <img src={image1} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>01 </span>
                <span> ”Está bem mais simples. Parabéns!"</span>
              </div>
            </div>

            <div className="item">
              <div className="item-image">
                <img src={image2} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>02 </span>
                <span> "Não tive dificuldades. Está bem<br/>fácil fazer tudo."</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab10;