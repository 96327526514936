import React from 'react';

import image from 'assets/images/immersion/tab-4-image.png';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab4 = () => {
  return (
    <div className="tab-content" data-index="3">
      <div className="container">
        <div className="flex-wrapper">
          <div className="left">
            <div className="left-line"></div>
            <div className="left-line"></div>

            <div className="left-image">
              <img src={image} alt="Imagem - Suvinil" />
            </div>

            <div className="left-title">Um leitor todo mundo quer. Se tiver custo a gente paga, porque reduz muito as chances de errar.</div>
          </div>

          <div className="content">
            <div className="content-description">
              <p>
                Algumas lojas informam que a Coral<br/>
                quem forneceu o leitor‭. ‬Em outras‭, <br/>
                assumem que eles mesmos<br/>
                compraram‭. ‬Nenhum dos que disse<br/>
                ter leitor comprado reclamou de ter<br/>
                que comprar o leitor de código de<br/>
                barras‭. ‬O que é unânime‭, ‬é a<br/>
                necessidade da conferência da<br/>
                base‭: ‬todas as visitas tiveram esse<br/>
                como primeiro tópico de fala‭.‬
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://drive.google.com/drive/u/0/folders/1JskKwCwOZ5Xvtso3OOrqYf5G9gVOZODH"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Ver conteúdo completo</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab4;