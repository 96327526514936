import React from 'react';

import image1 from 'assets/images/garage/tab-9-image-1.png';
import image2 from 'assets/images/garage/tab-9-image-2.png';

const Tab9 = () => {
  return (
    <div className="tab-content" data-index="8">
      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <h2 className="content-title">Teste 03<br/>Adilson, 45 anos.</h2>

            <div className="content-description">
              <p>
                Tendo bastante conhecimento da operação, também<br/>
                não apresentou dificuldade no teste. Navegou com<br/>
                rapidez através das atividades, mesmo sendo apresentado<br/>
                para novas funcionalidades, como o código de vendedor<br/>
                e o código do corante. Reagiu positivamente às mudanças,<br/>
                avaliando como necessárias.
              </p>
            </div>
          </div>

          <div className="listing">
            <div className="item">
              <div className="item-image">
                <img src={image1} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>01 </span>
                <span>"Está bem simples."</span>
              </div>
            </div>

            <div className="item">
              <div className="item-image">
                <img src={image2} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>02 </span>
                <span>"Não é difícil, todas as ações<br/>estão bem claras."</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab9;