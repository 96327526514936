import React, { useEffect } from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/immersion/tab-3-image.jpg';
import cursor from 'assets/vectors/cursor.svg';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab3 = () => {
  useSplit('.section-immersion .tab-content[data-index="2"] .image .image-title');
  useSplit('.section-immersion .tab-content[data-index="2"] .content .content-title');

  let index = 0;
  let transform = 0;

  const prevSlide = () => {
    const slide = document.querySelector('.section-immersion .tab-content[data-index="2"] .content-slide');
    const slideItems = slide.querySelector('.slide-items');
    const slideCursor = slide.querySelector('.cursor');
    const effect = document.createElement('div');
    effect.classList.add('effect');

    slideCursor.appendChild(effect);
    setTimeout(() => effect.classList.add('animate'), 50);
    setTimeout(() => effect.remove(), 1050);

    if (index === 0) {
      index = 3;
      transform = 2445;
      slideItems.style.transform = 'translateX(-2445px)';
    } else {
      index--;
      transform = transform - 815;
      slideItems.style.transform = 'translateX(-' + transform + 'px)';
    }
  }

  const nextSlide = () => {
    const slide = document.querySelector('.section-immersion .tab-content[data-index="2"] .content-slide');
    const slideItems = slide.querySelector('.slide-items');
    const slideCursor = slide.querySelector('.cursor');
    const effect = document.createElement('div');
    effect.classList.add('effect');

    slideCursor.appendChild(effect);
    setTimeout(() => effect.classList.add('animate'), 50);
    setTimeout(() => effect.remove(), 1050);

    if (index === 3) {
      index = 0;
      transform = 0;
      slideItems.style.transform = 'translateX(0px)';
    } else {
      index++;
      transform = transform + 815;
      slideItems.style.transform = 'translateX(-' + transform + 'px)';
    }

    console.log(transform, index);
  }

  useEffect(() => {
    const slide = document.querySelector('.section-immersion .tab-content[data-index="2"] .content-slide');
    const slideCursor = slide.querySelector('.cursor');
    const hoverLeft = slide.querySelector('.hover-left');
    const hoverRight = slide.querySelector('.hover-right');

    slide.addEventListener('mousemove', function(e) {
      let position_x = e.clientX;
      let position_y = e.clientY;

      if (slideCursor.classList.contains('hide')) slideCursor.classList.remove('hide');

      slideCursor.style.transform = 'translate('+ position_x +'px, '+ position_y + 'px';
    });

    slide.addEventListener('mouseenter', () => slideCursor.classList.remove('hide'));
    slide.addEventListener('mouseleave', () => slideCursor.classList.add('hide'));

    hoverLeft.addEventListener('mouseenter', () => slideCursor.classList.add('rotate'));
    hoverRight.addEventListener('mouseenter', () => slideCursor.classList.remove('rotate'));
  }, [])

  return (
    <div className="tab-content" data-index="2">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <img src={image} alt="Imagem - Suvinil" />
            <div className="image-title">Explorar.</div>
          </div>

          <div className="content">
            <div className="content-title">Explorar.</div>

            <div className="content-subtitle">
              <p>
                Como os usuários<br/>
                enxergam Selfcolor‭?‬
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://drive.google.com/drive/u/0/folders/1JskKwCwOZ5Xvtso3OOrqYf5G9gVOZODH"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Ver conteúdo completo</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>

            <div className="content-slide">
              <div className="hover-left" onClick={prevSlide} ></div>
              <div className="hover-right" onClick={nextSlide}></div>

              <div className="cursor hide">
                <img src={cursor} alt="Ícone - Suvinil" />
              </div>

              <div className="slide-items">
                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Complexidade</div>

                    <div className="item-description">
                      <p>Eu não sei mexer em nada‭! ‬Só sei fazer a tinta‭.‬</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>A pouca amigabilidade do sistema faz com que os operadores tenham insegurança na operação‭ - ‬principalmente por que colecionam um‭ ‬histórico de erros‭. ‬Quando perguntados a respeito de qualquer funcionalidade que não envolva dispensar a tinta‭, ‬conferir/registrar cor ou ver um relatório‭, ‬nenhum dos operadores soube operar‭ - ‬nem promotores‭.‬</p>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Lentidão</div>

                    <div className="item-description">
                      <p>O sistema podia ser muito mais leve!</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>A demora pra fazer pequenos comandos, além de aumentar o tempo de operações simples, induz ao erro se o usuário está com pressa. O sistema tem fama de lento, principalmente quando comparado à concorrência. Para evitar incômodos, os operadores negligenciam o que podem - como abastecimento de colorante - e inventam seus protocolos para diminuir o erro - como, por exemplo, nunca buscar uma cor pelo nome, sempre pelo código.</p>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Gambiarra</div>

                    <div className="item-description">
                      <p>Se a gente não fizer a gambiarra‭, ‬o cara ali do lado faz‭.‬</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>Seja para conquistar o cliente e "personalizar uma cor", seja para corrigir um erro que resultaria num prejuízo (muitas vezes por falta de colorante na máquina), a política da gambiarra está institucionalizada entre operadores. A pigmentação manual, assim como o tingimento de Glasurit - ou até mesmo de tintas Suvinil com colorantes Coral - é uma conduta comum entre os vendedores. Partindo do pressuposto que pode ser feito - e resulta na venda, fidelização do cliente ou aumento da praticidade, as negligências são comuns.</p>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="item-block">
                    <div className="item-title">Rastreabilidade</div>

                    <div className="item-description">
                      <p>Se deu problema‭, ‬o sistema da Suvinil não vai registrar quem fez‭. ‬Mas pela data e horário‭, ‬a gente tenta identificar‭.‬</p>
                    </div>
                  </div>

                  <div className="item-block">
                    <div className="item-description">
                      <p>O livre acesso à dispensa de tintas sem identificação causa muitos problemas com relação à conferência do erro‭: ‬muitas vezes‭, ‬as‭ ‬tintas são encontradas já erradas‭ - ‬sem nenhuma indicação de responsabilidade‭. ‬A necessidade de identificação de quem pigmenta‭ ‬é uma dor apontada em inúmeras visitas - tanto por vendedores, quanto por lojistas‭.‬</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab3;