import React from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/garage/tab-2-image.jpg';

const Tab2 = () => {
  useSplit('.section-garage .tab-content[data-index="1"] .image .image-title');
  useSplit('.section-garage .tab-content[data-index="1"] .content .content-title');

  return (
    <div className="tab-content" data-index="1">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <img src={image} alt="Imagem - Suvinil" />
            <div className="image-title">Dia 01 Imergir e empatizar.</div>
          </div>

          <div className="content">
            <div className="content-title">Dia 01 Imergir e empatizar.</div>

            <div className="content-description">
              <p>
                No primeiro dia da semana‭, ‬a programação<br/>
                voltou-se para a imersão nos achados<br/>
                apontados pela equipe da ioasys após as<br/>
                vivências‭ ‬‭- ‬dando abertura à discussão e<br/>
                fomentando a empatia com os stakeholders‭.‬
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab2;