import React from 'react';

import SectionLogin from 'components/SectionLogin';
import Overlay from 'components/Overlay';

const Login = () => {
  return (
    <>
      <main id="page-login" className="page">
        <SectionLogin />
      </main>

      <Overlay />
    </>
  )
}

export default Login;