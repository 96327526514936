import React from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/consolidate/tab-2-image.jpg';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab2 = () => {
  useSplit('.section-consolidate .tab-content[data-index="1"] .image .image-title');
  useSplit('.section-consolidate .tab-content[data-index="1"] .content .content-title');

  return (
    <div className="tab-content" data-index="1">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <img src={image} alt="Imagem - Suvinil" />
            <div className="image-title">Especificações.</div>
          </div>

          <div className="content">
            <div className="content-title">Especificações.</div>

            <div className="content-description">
              <p>
                Tendo sido discutido a respeito das possibilidades de organização para o novo sistema<br/>
                da Selfcolor‭, ‬chegou-se à solução de dividi-lo entre uma área de livre acesso e a área<br/>
                logada‭ - ‬tendo em vista a necessidade de manuseio gerencial em contraponto ao manuseio corriqueiro da máquina‭. ‬
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://drive.google.com/drive/u/0/folders/1JskKwCwOZ5Xvtso3OOrqYf5G9gVOZODH"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Ver conteúdo completo</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab2;