import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import axios from 'axios';

import logo from 'assets/vectors/logo-ioasys-login.svg';

const SectionLogin = () => {
  const cookie = document.cookie.includes('suvinil_logged=true');
  const [logged, setLogged] = useState();

  return (
    <>
      {logged || cookie ? <Redirect to='/home/' /> : ''}

      <section className="section section-login">
        <div className="logged">Logado com sucesso, redirecionando...</div>
        <div className="error">Usuario ou senha estão errados</div>

        <div className="container">
          <div className="wrapper">
            <div className="wrapper-logo">
              <img src={logo} alt="Logo - ioasys" />
            </div>

            <h1 className="wrapper-title">Apresentação Suvinil</h1>

            <div className="wrapper-description">
              <p>Para visualizar a apresentação,<br/>digite seu usuário e senha.</p>
            </div>

            <Formik
              initialValues={{ email: '', password: '' }}

              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = 'Campo obrigatório';
                } else if (!values.password) {
                  errors.password = 'Campo obrigatório';
                } return errors;
              }}

              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                let credentials = {email: values.email, password: values.password}
                axios.post(`https://gzowht7b6g.execute-api.us-east-1.amazonaws.com/default/suvinil-td-authentication`, credentials).then(res => {
                  document.querySelector('.logged').classList.add('active');
                  document.cookie = 'suvinil_logged=true';
                  window.logged = true;

                  setTimeout(() => setLogged(true), 3000);
                }).catch(error => {
                  document.querySelector('.error').classList.add('active');
                  setTimeout(() => document.querySelector('.error').classList.remove('active'), 2500);

                  window.logged = false;
                  document.cookie = 'suvinil_logged=false';
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form className="wrapper-form" onSubmit={handleSubmit}>
                  <div className="field-group">
                    <input
                      className="field"
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    
                    <span className={errors.email && touched.email ? 'active' : ''}>{errors.email && errors.email}</span>
                  </div>

                  <div className="field-group">
                    <input
                      className="field"
                      type="password"
                      name="password"
                      placeholder="Senha"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />

                    <span className={errors.password && touched.email ? 'active' : ''}>{errors.password && errors.password}</span>
                  </div>

                  <div className="form-submit" disabled={isSubmitting}>
                    <button type="submit">Entrar</button>
                  </div>

                  <div className={errors.length > 0 ? 'message active' : 'message'}>
                    <span>Usuario ou senha estão errados</span>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  )
}

export default SectionLogin;