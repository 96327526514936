import React, { useState, useEffect } from 'react';

import image1 from 'assets/images/consolidate/tab-3-image-1.jpg';
import image2 from 'assets/images/consolidate/tab-3-image-2.jpg';

const Tab3 = () => {
  const [videoUrl, setVideoUrl] = useState();

  useEffect(() => {
    const video = document.querySelector('#page-home .section-consolidate .tab-content[data-index="2"] .video');
    const videoContent = video.querySelector('.video-content');

    video.addEventListener('click', function(e) {
      if (e.target.contains(videoContent) && video.classList.contains('active')) setVideoUrl(false);
    });
  }, [])

  useEffect(() => {
    const video = document.querySelector('#page-home .section-consolidate .tab-content[data-index="2"] .video');

    if (videoUrl) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', videoUrl);
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
      iframe.setAttribute('allowfullscreen', 'true');

      video.querySelector('.video-content').appendChild(iframe);
    } else if (video.querySelector('.video-content iframe')) {
      setTimeout(() => video.querySelector('.video-content iframe').remove(), 700);
    }
  }, [videoUrl])

  return (
    <div className="tab-content" data-index="2">
      <div className={videoUrl ? 'video active' : 'video'}>
        <div className="video-content"></div>
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="item">
            <span>Fluxo área comum</span>

            <div className="image" onClick={() => setVideoUrl('https://www.youtube.com/embed/TVPZrESK-UM')}>
              <img src={image1} alt="Imagem - Suvinil" />
            </div>
          </div>

          <div className="item">
            <span>Fluxo área logada</span>

            <div className="image" onClick={() => setVideoUrl('https://www.youtube.com/embed/BlElhXljtYU')}>
              <img src={image2} alt="Imagem - Suvinil" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab3;