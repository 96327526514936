import React from 'react';

import image1 from 'assets/images/garage/tab-3-image-1.jpg';
import image2 from 'assets/images/garage/tab-3-image-2.jpg';
import image3 from 'assets/images/garage/tab-3-image-3.jpg';

const Tab3 = () => {
  return (
    <div className="tab-content" data-index="2">
      <div className="container">
        <div className="flex-wrapper">
          <div className="description">
            <p>
              Após a definição dos stakeholders presentes no ecossistema<br/>
              Suvinil, <strong>foram definidos os três principais - para quem a<br/>
              solução desenvolvida na semana deveria ser direcionada.</strong>
            </p>
          </div>

          <div className="flex">
            <div className="block">
              <div className="block-count">
                <span>01</span>
                <span>Lojista</span>
              </div>

              <div className="block-image">
                <img src={image1} alt="Imagem - Suvinil" />

                <div className="block-image--description">
                  <p>Dono da loja (ou gestor responsável), é o perfil que necessita fazer a gerência da loja. Quer ter rastreabilidade, relatórios sobre a máquina e pigmentações e dados estratégicos.</p>
                </div>
              </div>
            </div>

            <div className="block">
              <div className="block-count">
                <span>02</span>
                <span>Vendedor</span>
              </div>

              <div className="block-image">
                <img src={image2} alt="Imagem - Suvinil" />

                <div className="block-image--description">
                  <p>Operador direto do software, é quem está mais preocupado com a praticidade do dia-a-dia. Quer que o sistema seja um facilitador do cotidiano, com mais segurança e rapidez.</p>
                </div>
              </div>
            </div>

            <div className="block">
              <div className="block-count">
                <span>03</span>
                <span>Cliente final</span>
              </div>

              <div className="block-image">
                <img src={image3} alt="Imagem - Suvinil" />

                <div className="block-image--description">
                  <p>Apesar de ser mais indireto no sistema da selfcolor, é quem demanda o serviço - e está em contato direto com o vendedor. Quer mais confiabilidade nos produtos, além de entender a variabilidade de valores - a começar pelo orçamento.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab3;