import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import 'sass/main.scss';

import Home from 'container/home/';
import Login from 'container/login/';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Login} />
      <Route path="/home" component={Home} />
    </Switch>
  </BrowserRouter>, document.getElementById('root'));


