import React from 'react';

import image1 from 'assets/images/consolidate/tab-1-image-1.png';
import image2 from 'assets/images/consolidate/tab-1-image-2.png';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab1 = () => {
  const title = new Array(2).fill(0);

  return (
    <div className="tab-content" data-index="0">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <div className="image-1">
              <img src={image1} alt="Imagem - Suvinil" />
            </div>

            <div className="image-2">
              <img src={image2} alt="Imagem - Suvinil" />
            </div>
          </div>

          <div className="content">
            <h2 className="content-title">
              {title.map((number, index) => (
                <span key={index}>
                  <s data-index="0">C</s>
                  <s data-index="1">o</s>
                  <s data-index="2">n</s>
                  <s data-index="3">s</s>
                  <s data-index="4">o</s>
                  <s data-index="5">l</s>
                  <s data-index="6">i</s>
                  <s data-index="7">d</s>
                  <s data-index="8">a</s>
                  <s data-index="9">r</s>
                  <s data-index="10">.</s>
                </span>
              ))}
            </h2>

            <div className="content-description">
              <p>
                O protótipo do software foi revisado a partir dos insumos do teste<br/>
                e passou pelo processo de refinamento de UI/UX. Além de<br/>
                ajustada a navegação no que foi percebido no teste, também<br/>
                foram desenvolvidas as telas referentes aos demais fluxos - da<br/>
                área do vendedor e gerenciamento (logada). 
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://www.figma.com/file/cttMNAaN9VSV3Mq1x2YmQK/Self-color-%7C-Layout-vendedor?node-id=7%3A371"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Conheça a área comum</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>

            <div className="content-cta">
              <a
                href="https://www.figma.com/file/YgSt1Tdn92LrkZHeeXZsTU/Self-Color-%C3%81rea-logada?node-id=87%3A8896"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Conheça a área logada</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>
          </div>
        </div>

        <div className="count">03</div>
      </div>
    </div>
  )
}

export default Tab1;