import React from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/introduction/image.png';

const SectionIntroduction = () => {
  useSplit('.section-introduction .content .content-title');

  return (
    <section className="section section-introduction" data-index="1">
      <div className="background">
        <img src={image} alt="Imagem - Suvinil" />
      </div>

      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <div className="content-title">Desafio.</div>

            <div className="content-description">
              <p>
                Redesenhar o sistema selfcolor a partir de vivências reais do<br/>
                software‭ - ‬observando as demandas e a rotina de quem interage<br/>
                com‭ ‬ele‭, ‬além dos desafios internos do ponto de vista operacional‭.<br/>
                ‬Buscar uma solução aderente para os operadores‭ - ‬bem como para<br/>
                todo o ecossistema que alimenta o sistema tintométrico da Suvinil‭.‬
              </p>
            </div>

            <div className="content-list">
              <ul>
                <li>
                  <span>
                    <span>01.</span>
                    <span>Imersão</span>
                  </span>
                </li>

                <li>
                  <span>
                    <span>02.</span>
                    <span>Garagem<br/>Week</span>
                  </span>
                </li>

                <li>
                  <span>
                    <span>03.</span>
                    <span>Consoli-<br/>dação</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionIntroduction;