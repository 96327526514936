import React, { useEffect } from 'react';

import logo from 'assets/images/general/navigation-logo.png';

const Navigation = () => {
  useEffect(() => {
    const page = document.querySelector('main.page');
    const sections = page.querySelectorAll('.section');
    const navigation = page.querySelector('.navigation');
    const items = navigation.querySelectorAll('.item');

    page.addEventListener('changeNavigation', function() {
      setTimeout(function() {
        const currentSection = page.querySelector('.section.active');
        const currentSectionIndex = Number(currentSection.getAttribute('data-index'));
        const currentSectionTabs = currentSection.querySelector('.tabs');
        let lengthtabs = 0;
        let currentTabIndex = 0;
        if (currentSectionTabs) {
          lengthtabs = Number(currentSectionTabs.querySelectorAll('.tab-content').length);
          currentTabIndex = Number(currentSectionTabs.querySelector('.tab-content.active').getAttribute('data-index')) + 1;
        }
        const percentage = 100 / lengthtabs;
        const progress = percentage * currentTabIndex;

        if (currentSectionIndex <= 1) {
          navigation.classList.remove('active');
        } else {
          if (!navigation.classList.contains('active')) navigation.classList.add('active');

          navigation.querySelector('.item[data-index="' + currentSectionIndex + '"] .item-progress--line').style.width = progress + '%';

          if (currentSectionIndex === 2) {
            navigation.querySelector('.item[data-index="2"]').classList.remove('passed');
            navigation.querySelector('.item[data-index="2"]').classList.add('active');

            navigation.querySelector('.item[data-index="3"]').classList.remove('active');
            navigation.querySelector('.item[data-index="3"]').classList.remove('passed');

            navigation.querySelector('.item[data-index="4"]').classList.remove('active');
            navigation.querySelector('.item[data-index="4"]').classList.remove('passed');
          } else if (currentSectionIndex === 3) {
            navigation.querySelector('.item[data-index="2"]').classList.remove('active');
            navigation.querySelector('.item[data-index="2"]').classList.add('passed');

            navigation.querySelector('.item[data-index="3"]').classList.remove('passed');
            navigation.querySelector('.item[data-index="3"]').classList.add('active');

            navigation.querySelector('.item[data-index="4"]').classList.remove('active');
            navigation.querySelector('.item[data-index="4"]').classList.remove('passed');
          } else if (currentSectionIndex === 4) {
            navigation.querySelector('.item[data-index="2"]').classList.remove('active');
            navigation.querySelector('.item[data-index="2"]').classList.add('passed');

            navigation.querySelector('.item[data-index="3"]').classList.remove('active');
            navigation.querySelector('.item[data-index="3"]').classList.add('passed');

            navigation.querySelector('.item[data-index="4"]').classList.remove('passed');
            navigation.querySelector('.item[data-index="4"]').classList.add('active');
          }
        }
      }, 100)
    })

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      item.addEventListener('click', function() {
        const index = Number(item.getAttribute('data-index'));

        for (let x = 0; x < sections.length; x++) {
          const section = sections[x];
          const sectionIndex = Number(section.getAttribute('data-index'));
          const sectionTabs = section.querySelectorAll('.tabs .tab-content');

          if (sectionIndex === index) {
            section.classList.remove('passed');
            section.classList.add('active');

            for (let y = 0; y < sectionTabs.length; y++) {
              const tab = sectionTabs[y];
              const tabIndex = Number(tab.getAttribute('data-index'));

              if (tabIndex === 0) {
                tab.classList.remove('passed');
                tab.classList.add('active');
              } else {
                tab.classList.remove('passed');
                tab.classList.remove('active');
              }
            }
          } else if (sectionIndex < index) {
            section.classList.remove('active');
            section.classList.add('passed');

            for (let y = 0; y < sectionTabs.length; y++) {
              const tab = sectionTabs[y];

              tab.classList.remove('active');
              tab.classList.add('passed');
            }
          } else if (sectionIndex > index) {
            section.classList.remove('active');
            section.classList.remove('passed');

            for (let y = 0; y < sectionTabs.length; y++) {
              const tab = sectionTabs[y];

              tab.classList.remove('active');
              tab.classList.remove('passed');
            }
          }
        }

        for (let y = 0; y < items.length; y++) {
          const navItem = items[y];
          const navIndex = Number(navItem.getAttribute('data-index'));
          const progress = navItem.querySelector('.item-progress--line');

          if (index === navIndex) {
            progress.style.width = '0%';

            navItem.classList.remove('passed')
            navItem.classList.add('active');
          } else if (index > navIndex) {
            progress.style.width = '100%';

            navItem.classList.remove('active')
            navItem.classList.add('passed');
          } else if (index < navIndex) {
            progress.style.width = '0%';
            
            navItem.classList.remove('active')
            navItem.classList.remove('passed');
          }
        }
      })
    }
  }, []);

  return (
    <div className="navigation">
      <div className="navigation-logo">
        <img src={logo} alt="Logo - ioasys" />
      </div>

      <div className="navigation-listing">
        <div className="item" data-index="4">
          <div className="item-title">03. Consolidação</div>

          <div className="item-progress">
            <div className="item-progress--line"></div>
          </div>
        </div>

        <div className="item" data-index="3">
          <div className="item-title">02. Design Sprint</div>

          <div className="item-progress">
            <div className="item-progress--line"></div>
          </div>
        </div>

        <div className="item" data-index="2">
          <div className="item-title">01. Imersão</div>

          <div className="item-progress">
            <div className="item-progress--line"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation;