import React from 'react';

import Tab1 from './Tabs/Tab1.js';
import Tab2 from './Tabs/Tab2.js';
import Tab3 from './Tabs/Tab3.js';
import Tab4 from './Tabs/Tab4.js';

const SectionImmersion = () => {
  return (
    <section className="section section-immersion" data-index="2">
      <div className="tabs">
        <Tab1 />
        <Tab2 />
        <Tab3 />
        <Tab4 />
      </div>
    </section>
  )
}

export default SectionImmersion;