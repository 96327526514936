import React from 'react';

import image1 from 'assets/images/garage/tab-8-image-1.png';
import image2 from 'assets/images/garage/tab-8-image-2.png';

const Tab8 = () => {
  return (
    <div className="tab-content" data-index="7">
      <div className="container">
        <div className="flex-wrapper">
          <div className="content">
            <h2 className="content-title"> Teste 02<br/>Rafael, 22 anos.</h2>

            <div className="content-description">
              <p>
                Nunca operou nenhum software de pigmentação - acabou de<br/>
                ser contratado e ainda não recebeu treinamento.
              </p>

              <p>
                Sem nenhuma experiência anterior, teve dificuldades no<br/>
                manuseio - por que não conhecia ainda conceitos simples,<br/>
                como a purga. Apesar disso, com orientação, soube operar o<br/>
                software com segurança - tanto que fechou as caixas de<br/>
                diálogo automaticamente, sem nem mesmo ler a informação<br/>
                o que foi o maior indicativo do teste: a necessidade de evitar<br/>
                que o operador passe pela informação sem absorvê-la.
              </p>
            </div>
          </div>

          <div className="listing">
            <div className="item">
              <div className="item-image">
                <img src={image1} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>01 </span>
                <span>"Tá bem fácil, eu tive facilidade."</span>
              </div>
            </div>

            <div className="item">
              <div className="item-image">
                <img src={image2} alt="Imagem - Suvinil" />
              </div>

              <div className="item-content">
                <span>02 </span>
                <span> "É bem mais simples que aquele<br/>outro, né? Mais moderno."</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab8;