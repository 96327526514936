import React from 'react';

import { useSplit } from "utils/general";

import image from 'assets/images/garage/tab-5-image.jpg';
import arrowRight from 'assets/vectors/arrow-right.svg';

const Tab5 = () => {
  useSplit('.section-garage .tab-content[data-index="4"] .image .image-title');
  useSplit('.section-garage .tab-content[data-index="4"] .content .content-title');

  return (
    <div className="tab-content" data-index="4">
      <div className="container">
        <div className="flex-wrapper">
          <div className="image">
            <img src={image} alt="Imagem - Suvinil" />
            <div className="image-title">Dia 03 e 04 Priorizar e construir</div>
          </div>

          <div className="content">
            <div className="content-title">Dia 03 e 04 Priorizar e construir</div>

            <div className="content-description">
              <p>
                A cocriação elaborada pelo grupo no segundo dia serviu de insumo para que‭, ‬no terceiro‭, ‬a equipe da<br/>
                ioasys e os decisores do projeto pudessem trabalhar juntos no que seria a interface de teste‭. ‬As<br/>
                funcionalidades foram priorizadas em um recorte prioritário‭ que se adequasse à possibilidade da sprint‭.<br/>
                Seria‭ testada a função primordial do software‭: ‬a pigmentação de tintas‭ (‬e as minúcias de seu processo‭,<br/>
                que envolveriam a purga‭, ‬a recarga do Colorante‭, ‬a leitura do código de barras e a filtragem do produto<br/>
                relacionando cor‭, ‬acabamento e tamanho‭ ‬da embalagem‭). ‬
              </p>
            </div>

            <div className="content-cta">
              <a
                href="https://drive.google.com/drive/u/0/folders/1JskKwCwOZ5Xvtso3OOrqYf5G9gVOZODH"
                target="_blank"
                rel="noopener noreferrer"
                title="Baixar PDF"
                aria-label="Baixar PDF"
              >
                <span>Ver conteúdo completo</span>
                <img src={arrowRight} alt="Ícone - Suvinil" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tab5;