import React from 'react';

import Tab1 from './Tabs/Tab1.js';
import Tab2 from './Tabs/Tab2.js';
import Tab3 from './Tabs/Tab3.js';
import Tab4 from './Tabs/Tab4.js';
import Tab5 from './Tabs/Tab5.js';
import Tab6 from './Tabs/Tab6.js';
import Tab7 from './Tabs/Tab7.js';
import Tab8 from './Tabs/Tab8.js';
import Tab9 from './Tabs/Tab9.js';
import Tab10 from './Tabs/Tab10.js';

const SectionGarage = () => {
  return (
    <section className="section section-garage" data-index="3">
      <div className="tabs">
        <Tab1 />
        <Tab2 />
        <Tab3 />
        <Tab4 />
        <Tab5 />
        <Tab6 />
        <Tab7 />
        <Tab8 />
        <Tab9 />
        <Tab10 />
      </div>
    </section>
  )
}

export default SectionGarage;