import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import SectionHero from 'components/SectionHero';
import SectionIntroduction from 'components/SectionIntroduction';
import SectionImmersion from 'components/SectionImmersion';
import SectionGarage from 'components/SectionGarage';
import SectionConsolidate from 'components/SectionConsolidate';
import Navigation from 'components/Navigation';
import Overlay from 'components/Overlay';

const Home = () => {
  const cookie = document.cookie.includes('suvinil_logged=true');
  const logged = window.logged === true;

  const presentation = e => {
    const width = window.innerWidth;
    if (width <= 1100) return;
    const page = document.querySelector('main.page');
    const currentSection = page.querySelector('.section.active');
    if (!currentSection) return;
    const currentSectionIndex = Number(currentSection.getAttribute('data-index'));
    const currentSectionTabs = currentSection.querySelector('.tabs');
    let scroll = false;
    if (e.which === 39) {
      scroll = 'next';
    } else if (e.which === 37) {
      scroll = 'prev';
    }

    if (scroll === 'next') {
      if (page.classList.contains('transiting')) return;

      const nextSectionIndex = currentSectionIndex + 1;
      let nextSection, nextSectionTabs;
      if (nextSectionIndex !== 5) {
        nextSection = page.querySelector('.section[data-index="' + nextSectionIndex + '"]');
        nextSectionTabs = nextSection.querySelector('.tabs');
      }

      if (currentSectionTabs) {
        const sectionTabs = currentSectionTabs.querySelectorAll('.tab-content');
        const currentTab = currentSectionTabs.querySelector('.tab-content.active');
        const currentTabIndex = Number(currentTab.getAttribute('data-index'));
        const nextTabIndex = currentTabIndex + 1;
        const lastTabsIndex = sectionTabs.length;

        if (nextTabIndex === lastTabsIndex && nextSectionIndex !== 5) {
          currentSection.classList.add('passed');
          currentSection.classList.remove('active');

          nextSection.classList.add('active');

          currentTab.classList.add('passed');
          currentTab.classList.remove('active');

          if (nextSectionTabs) {
            nextSectionTabs.querySelector('.tab-content[data-index="0"]').classList.add('active');
          }
        } else if (nextTabIndex !== lastTabsIndex) {
          const nextTab = currentSectionTabs.querySelector('.tab-content[data-index="' + nextTabIndex + '"]');

          currentTab.classList.add('passed');
          currentTab.classList.remove('active');

          nextTab.classList.add('active');
        }
      } else {
        currentSection.classList.add('passed');
        currentSection.classList.remove('active');

        nextSection.classList.add('active');

        if (nextSectionTabs) {
          nextSectionTabs.querySelector('.tab-content[data-index="0"]').classList.add('active');
        }
      }
    } else if (scroll === 'prev') {
      if (page.classList.contains('transiting')) return;

      const nextSectionIndex = currentSectionIndex - 1;
      if (nextSectionIndex === -1) return;
      const nextSection = page.querySelector('.section[data-index="' + nextSectionIndex + '"]');
      const nextSectionTabs = nextSection.querySelector('.tabs');

      if (currentSectionTabs) {
        const currentTab = currentSectionTabs.querySelector('.tab-content.active');
        const currentTabIndex = Number(currentTab.getAttribute('data-index'));
        const nextTabIndex = currentTabIndex - 1;
        let lastTabsIndex;
        if (nextSectionTabs) {
          lastTabsIndex = Number(nextSectionTabs.querySelectorAll('.tab-content').length);
        }

        if (nextTabIndex === -1 && nextSectionIndex !== -1) {
          currentSection.classList.remove('active');

          nextSection.classList.remove('passed');
          nextSection.classList.add('active');

          if (currentSectionTabs) {
            setTimeout(() => currentSectionTabs.querySelector('.tab-content[data-index="0"]').classList.remove('active'), 300);
          }

          if (nextSectionTabs) {
            nextSectionTabs.querySelector('.tab-content[data-index="' + (lastTabsIndex - 1) + '"]').classList.remove('passed');
            nextSectionTabs.querySelector('.tab-content[data-index="' + (lastTabsIndex - 1) + '"]').classList.add('active');
          }
        } else if (nextTabIndex !== -1) {
          const nextTab = currentSectionTabs.querySelector('.tab-content[data-index="' + nextTabIndex + '"]');

          currentTab.classList.remove('passed');
          currentTab.classList.remove('active');

          nextTab.classList.remove('passed');
          nextTab.classList.add('active');
        }
      } else {
        currentSection.classList.remove('active');
        currentSection.classList.remove('passed');

        nextSection.classList.remove('passed');
        nextSection.classList.add('active');

        if (nextSectionTabs) {
          nextSectionTabs.querySelector('.tab-content[data-index="0"]').classList.add('active');
        }
      }
    }

    if (scroll === 'prev' || scroll === 'next') {
      if (page.classList.contains('transiting')) return;
      e.preventDefault();
      page.classList.add('transiting');
      page.dispatchEvent(new Event('changeNavigation'));
      setTimeout(() => page.classList.remove('transiting'), 1000);
    }
  }

  useEffect(() => document.addEventListener('keydown', presentation), []);

  return (
    <>
      {cookie || logged ? '' : <Redirect to='/' />}

      <main id="page-home" className="page">
        <SectionHero />
        <SectionIntroduction />
        <SectionImmersion />
        <SectionGarage />
        <SectionConsolidate />
        <Navigation />
      </main>

      <Overlay />
    </>
  )
}

export default Home;